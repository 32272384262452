@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(
  "https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@200;300;500;600&family=Playfair+Display&family=Cormorant+Garamond:wght@400;600;700&family=Montserrat:wght@200;300;500;600&&family=Cardo:wght@200;300;500;600&family=Muli:wght@200;300;500;600&family=Quattrocento+Sans:wght@400;700&family=Source+Sans+3:wght@200;400&display=swap"
);

:root{
  --primary: #2b2b2b;
  --secondary: #545454;
  --tertiary: #9b9b9b;
  --white: #ffffff;
  --error: #ff4a4a;
}
html {
  position:relative;
  overflow-x:clip;
}
/* base styles & title */
body{
  font-family: "Josefin Sans";
  font-size: 0.8rem;
  margin: 0;
  font-weight: 200;
  color: var(--secondary);
  position: relative;
  overflow: hidden;
}
.App{
  max-width: 1024px;
  margin: 0 auto;
  margin-bottom: 50px;
}
.header {
  display: flex;
  align-items: baseline;
  margin: 0 1em;
}
.full-text{
  display: flex;
  gap: 10px;
  margin-left: auto;
  margin-right: 0;
}
.short-text{ display: none; }
@media (max-width: 768px) {
    .short-text { 
      display: flex;
      margin-left: auto;
      margin-right: 0;
    }
    .full-text { display: none; }
}
.header h1{
  font-family: "Cormorant Garamond";
  font-weight: 600;
  letter-spacing: 3px;
  font-size: 1.4rem;
  color: var(--primary);
  margin-top: 50px;
  margin-bottom: 50px;
  display: inline-flex;
}
.title p{
  text-align: center;
  margin: 0;
}
.header h2{
  font-family: "Josefin Sans";
  font-weight: 600;
  letter-spacing: 2px;
  color: var(--tertiary);
  margin-left: auto; 
  margin-right: 0;
  font-size: 0.7rem;
}
.header h2:hover{
  color: var(--primary);
}

.left {
  float:left;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* image grid styles */
.img-grid{
  margin: 0 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 15px;
}
.img-wrap {
  overflow: hidden;
  position: relative;
}
.img-wrap img{
  display: block;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 1;

}
@media (max-width: 860px) {
  .img-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }
}

/* menu styles */
.menu {
  display: flex;
  font-family: "Josefin Sans";
  font-weight: 600;
  letter-spacing: 3px;
  font-size: 1rem;
  color: var(--tertiary);
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.menu li{
  margin: 20px;
}
.menu li:hover{
  color: var(--primary);

}

/* modal styles */
.backdrop {
  top:0;
  bottom:0;
  left:0;
  right:0;
  background: rgba(255, 255, 255, 0.95);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
}

.backdrop img{
  max-height: 80vh;
  /* cursor: -moz-zoom-in; 
  cursor: -webkit-zoom-in;
  cursor: zoom-in; */
  overflow: auto;
}
.img-caption {
  position: absolute;
  display: grid;
  box-sizing: border-box;
  grid-gap: 15px;
  /* background: rgba(255, 255, 255, 1); */
  /* grid-template-columns: auto minmax(auto, 1fr); */
}

@media (max-width: 800px) {
  .backdrop {
    background: rgba(255, 255, 255, 0.9);
  }
}

.me--section--description {
  font-size: 15px;
  font-weight: 300;
  margin: 0 1em;
}

.me--section--image {
  margin: 0 1em;
}

.menu-open .App {
  max-width: none; /* Allow full-width when the menu is open */
  width: 100vw;
}